<template>
  <div id="app">
    <!-- Custom Sidebar for displaying the node information -->
    <div :class="mobileSearchBarClass" v-if="this.mobileScreen">
      <div>
        <div class="leftbar-title">
          My Search
        </div>
        <word-picker-mobile
          @dictWordSelected="onMobileWordSelected"
          @wordRemoved="onMobileWordRemoved"
          @nosearchresults="joinCommunity"
        />
        <div v-if="showJoinCommunity" class="leftbar-title">
          Can't find your keyword in the list?
          <a
            href="https://www.mindspeller.com/#/respondent_signup"
            target="_blank"
            >Join our community to add it here</a
          >
        </div>
      </div>
    </div>
    <div class="sidebar-wrapper">
      <Sidebar :wordData="wordData" @networkChange="updateIframeSrc" :mobileScreen="mobileScreen"/>
    </div>
    <!-- Grouped Instructions Button and Pointing Label -->

    <!-- Instructions Popup -->

    <!-- TensorBoard iframe embedded -->
    <iframe v-if="!this.mobileScreen"
      :src="iframeSrc"
      id="tensorboard-frame"
    ></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Sidebar from "./Sidebar.vue";
import { mapGetters } from "vuex";
import WordPickerMobile from "@/components/target_pickers/ias/WordPickerMobile.vue";
import { alert_info, genericDialog, toast_success } from '@/helpers/alert_helper.js';


export default {
  name: "Dashboard",
  components: {
    Sidebar, WordPickerMobile,
  },
  computed: {
    ...mapGetters(["showInstructionPopup"]),
    mobileSearchBarClass() {
      return this.wordData && Object.keys(this.wordData).length
        ? "b2c-searchbar-wrapper-active"
        : "b2c-searchbar-wrapper-inactive";
    },
  },
  data() {
    return {
      index: null, // Index selected from TensorBoard
      wordData: null, // Stores word data received from API
      label: null,
      currentlang: '',
      organizationName: '',
      iframeSrc: '',
      mobileScreen: false,
      showJoinCommunity: false,
    };
  },
  created() {
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
    this.organizationName = this.currentlang === 'en' ? 'en' : 'nl';
    this.iframeSrc = this.currentlang === 'en'
        ? 'https://tensorboard.mindspeller.com/#projector'
        : 'https://tensorboardnl.mindspeller.com/#projector';
  },
  methods: {
    updateIframeSrc({ newSrc, organizationName }) {
      if (newSrc) {
        this.iframeSrc = newSrc;
        this.organizationName = organizationName;
      } else {
        console.warn("Empty newSrc received");
      }
    },
    handleIframeMessage(event) {
      if (event.data.type === "tensorboard-label") {
        const sidebar_selection = event.data.data;
        if (sidebar_selection) {
          this.label = sidebar_selection;
          this.get_label();
        } else {
          console.log("Sidebar selection is empty");
        }
      } else if (event.data.type === "clear-selection") {
        console.log("Clear selection received");
        this.wordData = null;
      }
    },
    async get_label() {
      let payload = {
        label: this.label,
        organisation: this.organizationName,
      };
      console.log(payload);
      try {
        // Send POST request to get the word at the specified index
        const response = await axios.post(
          "/api/cas/survey_cleaning/projector",
          payload
        );
        let wordData = response.data;
        this.wordData = wordData;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },
    showAlert(message) {
      alert(message);
    },
    onMobileWordSelected(originalFormat) {
      this.showJoinCommunity = false;
      this.wordData = originalFormat;
    },
    onMobileWordRemoved() {
      this.wordData = null;
      this.showJoinCommunity = false;
      console.log("Mobile word removed.");
    },
  },
  watch: {
    wordData(newVal, oldVal) {
      if (!oldVal && newVal && Object.keys(newVal).length && !this.mobileScreen) {
        // Trigger alert when wordData transitions from null to full
        alert_info('Info', 'Click "ISOLATE" to continue. Use the slider to limit the neighbors.', 'justify');
      }
    },
    organizationName(newVal) {
      // Update iframeSrc if organizationName changes
      this.iframeSrc = this.currentlang === 'en'
        ? 'https://tensorboard.mindspeller.com/#projector'
        : 'https://tensorboardnl.mindspeller.com/#projector';
    }
  },
  mounted() {
    // Add event listener for messages from the iframe
    // this.openInstructionsPopup();
    window.addEventListener("message", this.handleIframeMessage);
    if (window.innerWidth <= 1023) {
      this.mobileScreen = true;
      this.showMobilePopup = true;
    }
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener("message", this.handleIframeMessage);
  },
};
</script>

<style>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.sidebar-wrapper {
  position: fixed;
  top: 10%;
  left: 0;
  width: 22%;
  height: calc(100% - 60px);
  z-index: 1;
}

/* Wrapper for Instructions Button and Label */
.instructions-wrapper {
  position: fixed;
  top: 20%; /* Adjust this for vertical alignment */
  left: 22%; /* Adjust this for horizontal alignment */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Space between the button and the label */
  z-index: 2;
}

.icon {
  font-size: 1.5em;
  color: white;
  pointer-events: none;
}

.instructions-button {
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.instructions-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.instructions-button:active {
  transform: scale(1);
}

.pointing-label {
  display: inline-block !important;
  background-color: #7878e9;
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite;
  margin-left: 10px; /* Ensures spacing from the button */
  position: relative;
}

/* Pointing Arrow */
.pointing-label::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* Position arrow on the left side */
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #7878e9 transparent transparent;
}

.b2c-searchbar-wrapper-active {
  position: fixed;
  background-color: #fff;
  /* Adjust this value to match your top header height */
  top: 145px;
  left: 0;
  right: 0;
  /* width: 22%; */
  /* height: calc(100% - 60px); */
  z-index: 3;
}

.b2c-searchbar-wrapper-inactive {
  position: fixed;
  background-color: #fff;
  /* Adjust this value to match your top header height */
  top: 300px;
  left: 0;
  right: 0;
  /* width: 22%; */
  /* height: calc(100% - 60px); */
  z-index: 3;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@media screen and (min-width: 1600px) {
  .instructions-wrapper {
  position: fixed;
  top: 19.5%;
  left: 17%;
  /* margin-left: 10px; */

}
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .instructions-wrapper {
  position: fixed;
  top: 27%;
  left: 20%;
  /* margin-left: 10px; */

}
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .instructions-wrapper {
  position: fixed;
  top: 29%;
  left: 23%;
  /* margin-left: 10px; */
}
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .instructions-button {
  position: fixed;
  top: 25%;
  left: 29%;
  /* margin-left: 10px; */

}

}

@media only screen and (max-width: 768px) {
  /* Top Taskbar */
  .top-taskbar {
    min-height: 120px;
    max-height: 170px;
    padding: 5px;
    /* flex-direction: column; Stack items if necessary */
  }

  /* Logo Container */
  .logo-header-container {
    margin: 0 auto;
    text-align: center;
    /* flex-direction: column; */
  }
  .tagline-for-header {
    white-space: nowrap;
    font-weight: bold;
    font-size: 1em;
  }
  /* Divider */
  .divider {
    height: 40px; /* Reduced height */
    margin: 0 10px;
  }

  /* Taglines */
  .tagline {
    font-size: 0.9em;
    white-space: normal; /* Allow wrapping */
    text-align: end;
  }
  .left,
  .right {
    font-size: 0.9em;
  }

  /* Logo Image */
  .top-taskbar-logo {
    max-width: 150px; /* Restrict the maximum width on mobile */
    height: auto; /* Maintain aspect ratio */
  }

  /* Reward Icon */
  .reward-icon-wrapper {
    width: 50px; /* Smaller circle */
    height: 70px;
    width: 50px;
    top: 25%; /* Adjust vertical position */
    left: 3%; /* Adjust horizontal position */
    font-size: 0.5em;
  }

  .tooltip-container .tooltip-text {
    font-size: 0.55em;
    width: 300px;
  }
  /* User Icon and Brand Icon */
  .user-login-icon-wrapper,
  .brand-icon-wrapper {
    width: 50px;
    height: 50px;
    top: 15%; /* Adjust vertical position */
    /* For brand icon adjust left/right if necessary */
  }
  .leftbar-title {
    font-size: 1em;
    padding: 6px 8px;
    margin-bottom: 8px;
  }

  .cta-icon-wrapper {
    width: 50px; /* Smaller circle */
    height: 70px;
    width: 50px;
    top: 25%; /* Adjust vertical position */
    right: 3%; /* Adjust horizontal position */
    font-size: 0.5em;
    animation: bounce 1.5s infinite;
  }
  .mindsearch-image {
    max-width: 120px;
    max-height: 120px;
  }

  .login-user-icon {
    font-size: 3em;
  }

  /* Login Text */
  .login-text {
    font-size: 1.5em;
    bottom: -20px;
  }

  /* iFrame */
  #second-iframe {
    height: 100%;
    /* padding-top: 10px; */
  }

  /* Sidebar */
  .sidebar-wrapper {
    /* position: fixed; */
    /* top: 40%; */
    left: 0;
    width: 100%;
    bottom: 20%;
    background-color: white;
    overflow-y: auto;
  }
  .b2c-searchbar-wrapper-active {
    top: 38%;
    right: 0;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
    background-color: white;
  }

  .b2c-searchbar-wrapper-active {
    top: 18%;
    right: 0;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
    background-color: white;
  }

}
@media only screen and (max-width: 480px) {
  .align{
    gap:13px !important
  }
  .popup-footer{
    flex-direction: column;
  }
  .primary-button{
    height: auto;
    width: 100%;
  }
  .footer-inner{
    /* flex-direction: column; */
    font-size: 10px;
  }
  .legal{
    display: none;
  }
  .b2c-searchbar-wrapper-inactive {
    top: 115px;
  }
  .leftbar-title {
    margin-bottom: 0px !important;
  }
  .top-taskbar-logo{
    max-width: 150px; /* Further restrict the size for very small screens */
    height: auto; /* Maintain aspect ratio */
  }
  .tagline-for-header{
    display: none;
  }
  .tagline {
    text-align: left;
  }
}
</style>
